<script>
import { get } from 'vuex-pathify'
import { createPortals } from '@/__shared/utils/lib_utils'
import { generateProductSchema } from './product-schema'

const ProductPortals = createPortals(require.context('@/site', true, /portal_Product(-.+)?\.vue/))

export default {
	lang: 'shop',
	components: { ProductPortals },
	metaInfo() {
		let title = '...'
		let description = ''
		let script = []
		let link = []
		if (this.selectedVariant) {
			title = this.product.name
			description = this.product.info?.description || this.product.name
			script.push({
				innerHTML: JSON.stringify(generateProductSchema(this.product, this.selectedVariant), null, 2),
				type: 'application/ld+json',
				body: true,
			})
			link.push({ rel: 'canonical', href: window.location.href })
		}
		return {
			title,
			meta: [{ vmid: 'd', name: 'description', content: description }],
			script,
			link,
		}
	},
	data() {
		return {
			product: {},
			relatedProducts: [],
			loading: true,
			selectedVariantId: null,
			selectedVariant: null,
			customization: {},
			validation: {},
			qty: 1,
			items: [],
		}
	},
	computed: {
		routeParams: get('route@params'),
		routeVariantId: get('route@query.variant'),
		routeAddToCart: get('route@query.add-to-cart'),
		pageLoading() {
			return this.loading
		},
		total() {
			let total = this.selectedVariant.pvPrice.price * this.qty
			return Number(total.toFixed(2))
		},
		outOfStock() {
			return this.selectedVariant.stock.availability == 'OutOfStock'
		},
		maxStockQty() {
			let { maxBuyableQty, infiniteQty, qty } = this.selectedVariant.stock
			if (maxBuyableQty >= 1) {
				if (infiniteQty) return maxBuyableQty
				else return Math.min(qty, maxBuyableQty)
			} else {
				if (infiniteQty) return 0
				else return qty
			}
		},
		hasUniqueVariant() {
			return this.product.hasUniqueVariant
		},
	},
	watch: {
		'routeParams.id'() {
			this.loadData()
		},
		selectedVariantId(value) {
			this.selectVariant(value)
		},
	},
	methods: {
		processLinks({ product }) {
			let urlNames = []
			this.items = product.categories.map((category) => {
				urlNames.push(category.urlName)
				let item = {
					text: category.name,
					to: { name: 'shop', params: { filters: [...urlNames] } },
				}
				return item
			})
		},
		loadData() {
			let { id, urlName } = this.routeParams
			this.product = {}
			this.selectedVariant = null

			this.$shopApi.get({
				loading: (v) => (this.loading = v),
				url: `/catalog/product/${id}`,
				onSuccess: async ({ data, options }) => {
					let { product } = data
					if (!product) {
						//404
						return
					}

					if (product.urlName != urlName) {
						this.$router.replace({
							name: 'product',
							params: { id, urlName: product.urlName },
							query: this.$route.query,
						})
					}

					this.product = product
					this.relatedProducts = data.relatedProducts
					this.selectVariant(this.routeVariantId)
					this.autoAddToCart()
					this.processLinks(data)
					this.$eventer.trigger('page:view')
					this.$prerenderReady()
				},
			})
		},
		selectVariant(variantId) {
			if (variantId && variantId == this.selectedVariant?.id) return

			let variant = null
			let isDefaultVariant = false
			if (variantId) variant = this.product.variants.find((variant) => variant.id == variantId)
			if (!variant) {
				isDefaultVariant = true
				variant = this.product.variants.find((variant) => variant.main)
				if (!variant) variant = this.product.variants[0]
			}

			if (this.routeVariantId != variant.id) {
				if (isDefaultVariant && this.routeVariantId) {
					this.$router.replace({
						query: { variant: undefined },
						params: { savePosition: true },
					})
				} else if (this.selectedVariant || this.routeVariantId) {
					this.$router.replace({
						query: { variant: variant.id },
						params: { savePosition: true },
					})
				}
			}

			this.selectedVariant = variant
			this.selectedVariantId = variant.id
			this.$eventer.trigger('product:view', {
				variant: this.selectedVariant,
				product: this.product,
			})
		},
		async autoAddToCart() {
			if (this.routeAddToCart != 1) return
			await this.$shopApi.post({
				url: '/cart/item',
				loading: false,
				data: {
					refType: 'product',
					refId: this.selectedVariant.id,
					qty: 1,
				},
				onSuccess: async ({ data }) => {
					this.$store.set('cart/order', data.order)
				},
			})
			await this.$router.push({ query: { ...this.$route.query, 'add-to-cart': undefined } })
			this.$store.set('shop/cartDrawer', true)
		},
	},
	created() {
		this.loadData()
	},
}
</script>

<template>
	<div
		v-if="loading"
		class="d-flex justify-center py-16 mt-8"
		:style="{ 'min-height': 'calc(100vh - 100px)' }"
	>
		<v-progress-circular :size="70" :width="7" color="primary" indeterminate />
	</div>
	<div v-else>
		<ProductPortals />
		<Container :fluid="$vuetify.breakpoint.xs" max-width="90%" class="mt-2 mt-sm-8 pb-5 white">
			<v-row>
				<v-col cols="12" md="6">
					<ProductImagesCarousel :images="product.images" />
					<portal-target name="Product.below-images" multiple />
				</v-col>
				<v-col cols="12" md="6" class="px-2 px-sm-5 px-lg-15">
					<div class="px-6 px-sm-0">
						<div class="d-flex align-center justify-center justify-md-start">
							<v-breadcrumbs divider="/" :items="items" class="pa-0">
								<template v-slot:item="{ item }">
									<v-breadcrumbs-item :to="item.to">
										<div class="grey--text text-uppercase">
											{{ item.text }}
										</div>
									</v-breadcrumbs-item>
								</template>
							</v-breadcrumbs>
						</div>
						<div class="h4 text-uppercase font-weight-bold text-center text-md-start">
							{{ product.name }}
						</div>
						<v-row>
							<v-col cols="12 ">
								<div style="position: relative">
									<PriceLayout
										:price="selectedVariant.pvPrice.price"
										:prev-price="selectedVariant.pvPrice.prevPrice"
										:discount-pct="selectedVariant.pvPrice.discountPct"
										:unit-metric="product.packMetric ? product.unitMetric : null"
										:currency="selectedVariant.pvPrice.priceConfig.currency.sign"
										price-class="s3 gold--text"
										discount-class="gold white--text font-0 font-weight-bold pa-2 ml-3"
										class="my-5"
									/>
									<v-row class="mt-3" align="end">
										<v-col cols="12" sm="6" v-if="!hasUniqueVariant">
											<VariantsSelector
												v-model="selectedVariantId"
												:product="product"
												:show-select-input="true"
												key-class="font-weight-bold pb-1 text-uppercase"
											/>
										</v-col>
										<v-col
											cols="12"
											:sm="hasUniqueVariant ? 12 : 6"
											v-if="selectedVariant.type == 'physical' && !outOfStock"
										>
											<div class="font-weight-bold pb-1">CANTIDAD</div>
											<QtyInput
												class="qty-input"
												v-model="qty"
												:max-qty="maxStockQty"
												:unit-metric="product.unitMetric"
												:pack-metric="product.packMetric"
												:step="selectedVariant.metricFactor"
											/>
										</v-col>
									</v-row>
									<v-row align="center">
										<v-col cols="6" class="py-0">
											<div class="d-flex align-center">
												<span class="font-weight-bold font-weight-bold t5"> TOTAL </span>
												<span class="s3 ml-5">
													<PriceLayout
														price-class="gold--text"
														:price="total"
														:currency="selectedVariant.pvPrice.priceConfig.currency.sign"
													/>
												</span>
											</div>
										</v-col>
										<v-col
											cols="6"
											class="py-0 pl-5 d-flex align-center justify-end justify-md-start"
										>
											<ProductPayMethods />
										</v-col>
									</v-row>
									<portal-target name="Product.actions" multiple>
										<ProductActions
											:product="product"
											:variant="selectedVariant"
											:customization="customization"
											:qty="qty"
											cta-text="Comprar ahora"
											@validation="validation = $event"
											class="mt-8"
										/>
										<ProductWishlistText text="Agregar a favoritos" class="mt-3" />
									</portal-target>
									<ProductShare class="mt-10" />
									<portal-target name="Product.below-actions" multiple />
								</div>
							</v-col>
						</v-row>
					</div>
				</v-col>
			</v-row>
		</Container>
		<portal-target name="Product.info" multiple />
		<div v-if="relatedProducts.length" class="pt-10">
			<div class="s2 text-center font-weight-bold py-2 title-border">Te puede interesar</div>
			<Container max-width="90%">
				<ProductsCarousel :products="relatedProducts" list-id="related-products" />
			</Container>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.title-border {
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
}
/*.qty-input {
	width: 100%;
	border: 1px solid #bbb;
	height: 56px;
	padding: 0 4px;
}
.qty-input ::v-deep .container-text-field {
	width: 100%;
	.v-input {
		width: 100% !important;
		max-width: 100% !important;
		.v-input__slot:before {
			border-color: transparent !important;
		}
	}
}*/
</style>
