import { render, staticRenderFns } from "./view_Product.vue?vue&type=template&id=2cb32a24&scoped=true&"
import script from "./view_Product.vue?vue&type=script&lang=js&"
export * from "./view_Product.vue?vue&type=script&lang=js&"
import style0 from "./view_Product.vue?vue&type=style&index=0&id=2cb32a24&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cb32a24",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem,VCol,VProgressCircular,VRow})
