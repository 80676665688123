<script>
export default {
	lang: 'shop',
	props: {
		product: Object,
		variant: Object,
		ctaText: String,
		customization: Object,
		qty: Number,
	},
	data() {
		return {
			ctaIsLoading: false,
			hover: false,
		}
	},
	computed: {
		outOfStock() {
			return this.variant.stock.availability == 'OutOfStock'
		},
		maxStockQty() {
			let { maxBuyableQty, infiniteQty, qty } = this.variant.stock
			if (maxBuyableQty >= 1) {
				if (infiniteQty) return maxBuyableQty
				else return Math.min(qty, maxBuyableQty)
			} else {
				if (infiniteQty) return 0
				else return qty
			}
		},
	},
	methods: {
		async ctaClicked() {
			return this.$shop.addToCart({
				productId: this.product.id,
				variantId: this.variant.id,
				qty: this.qty,
				sum: true,
				customization: this.customization,
				loading: (v) => (this.ctaIsLoading = v),
				onValidation: ({ validation }) => this.$emit('validation', validation),
			})
		},
	},
}
</script>

<template>
	<div>
		<div>
			<Button
				:loading="ctaIsLoading"
				@click.stop="ctaClicked"
				:disabled="outOfStock"
				block
				depressed
				:outlined="!hover"
				@mouseenter="hover = true"
				@mouseleave="hover = false"
				color="gold"
				class="s1"
			>
				<v-icon small class="mr-1">mdi-cart-plus</v-icon>
				{{ ctaText || $lang('Comprar') }}
			</Button>
		</div>
	</div>
</template>
