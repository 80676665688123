<script>
import visa from './assets/logo-visa.webp'
import mc from './assets/logo-mastercard.webp'
import diners from './assets/logo-diners.webp'
import mp from './assets/mercadopago-logo.webp'
import posnet from './assets/e-posnet.webp'

export default {
	data() {
		return {
			overlay: false,
			creditCards: {
				visa: {
					name: 'Visa',
					img: visa,
				},
				mastercard: {
					name: 'Mastercard',
					img: mc,
				},
				dinners: {
					name: 'Diners Club International',
					img: diners,
				},
			},
			secondCreditCards: {
				mercadopago: {
					name: 'Mercadopago',
					img: mp,
				},
				eposnet: {
					name: 'E-posnet',
					img: posnet,
				},
			},
		}
	},
}
</script>

<template>
	<v-dialog transition="dialog-top-transition" max-width="600">
		<template v-slot:activator="{ on, attrs }">
			<div v-bind="attrs" v-on="on" class="hover d-lg-flex gold--text t4 text-uppercase">
				Ver medios de pago
			</div>
		</template>
		<template v-slot:default="dialog">
			<div class="white px-5 px-md-8 py-5">
				<div class="d-flex justify-space-between align-center">
					<div class="font-2 font-md-3 font-weight-medium black--text mr-10 text-uppercase">
						Métodos de pago
					</div>
					<v-btn class="white--text" @click="overlay = false" icon>
						<v-icon color="black" @click="dialog.value = false">mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="my-4">
					<div class="black--text text-uppercase font-1 font-md-2">tarjetas de crédito 3 cuotas</div>
					<div class="d-flex align-center">
						<div v-for="(card, index) of creditCards" :key="index" class="mr-2">
							<v-tooltip top v-if="card.img">
								<template v-slot:activator="{ on, attrs }">
									<Media
										:src="card.img"
										max-height="2.5rem"
										max-width="2.5rem"
										contain
										v-bind="attrs"
										v-on="on"
									/>
								</template>
								<span>{{ card.name }}</span>
							</v-tooltip>
						</div>
					</div>
				</div>
				<div class="my-4">
					<div class="black--text text-uppercase font-1 font-md-2">tarjetas de débito</div>
					<div class="d-flex align-center">
						<div v-for="(card, index) of creditCards" :key="index" class="mr-2">
							<v-tooltip top v-if="card.img">
								<template v-slot:activator="{ on, attrs }">
									<Media
										:src="card.img"
										max-height="2.5rem"
										max-width="2.5rem"
										contain
										v-bind="attrs"
										v-on="on"
									/>
								</template>
								<span>{{ card.name }}</span>
							</v-tooltip>
						</div>
					</div>
				</div>
				<div class="my-4">
					<div class="black--text text-uppercase font-1 font-md-2">Pagá seguro a través de</div>
					<div class="d-flex align-center">
						<div v-for="(card, index) of secondCreditCards" :key="index" class="mr-2">
							<v-tooltip top v-if="card.img">
								<template v-slot:activator="{ on, attrs }">
									<Media
										:src="card.img"
										max-height="2.5rem"
										max-width="2.5rem"
										contain
										v-bind="attrs"
										v-on="on"
									/>
								</template>
								<span>{{ card.name }}</span>
							</v-tooltip>
						</div>
					</div>
				</div>
				<div class="my-4">
					<div class="text-uppercase font-1 font-md-2">Pagá en efectivo contra entrega</div>
					<div class="font-0 mt-1 grey--text darken-2">HABILITADO PARA VICENTE LÓPEZ Y ALEDAÑOS</div>
				</div>
				<div class="my-4">
					<div class="text-uppercase font-1 font-md-2">Pagá con transferencia bancaria</div>
					<div class="font-0 mt-1 grey--text darken-2">
						RECIBIRÁS LOS DATOS AL FINALIZAR LA COMPRA. UNA VEZ PROCESADO EL PAGO ENVIAREMOS TU PEDIDO.
					</div>
				</div>
			</div>
		</template>
	</v-dialog>
</template>

<style scoped>
.hover:hover {
	cursor: pointer;
	color: var(--v-link-base);
}
</style>
